// If you want to override variables do it here
@import "variables";

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "layout";
@import "example";

// If you want to add custom CSS you can put it here.
@import "custom";

.font-bold {
  font-weight: 700;
}

.mb-12 {
  margin-bottom: 12px;
}

.el-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.display-none {
  display: none !important;
}

.active-item {
  color: #006AF5;
}